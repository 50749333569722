/*
 * Below classes (randomly generated by package) are replaced with element hierarchy.
 */



/* ------------------START----------------------- */
/*.sc-cwHptR,
.sc-aXZVg {*/
.reorder-board > div:first-child > div:first-child,
.reorder-board > div:first-child > div:first-child > div > div:nth-child(2) > div > div:first-child {
  width: 100%;
}
/* -------------------END------------------------ */


/* ------------------START----------------------- */
/*.sc-eqUAAy {*/
.reorder-board > div:first-child > div:first-child > div {
  width: 48.5%;
  margin: 0 12px !important;
}
/* -------------------END------------------------ */



/* ------------------START----------------------- */
/*.sc-dhKdcB {*/
.reorder-board > div:first-child > div:first-child > div > div:nth-child(2) > div > div:first-child > div:first-child {
  max-width: 100% !important;
}
/* -------------------END------------------------ */



/* ------------------START----------------------- */
/*.gwsZBW {*/
.reorder-board > div:first-child > div:first-child > div > div:nth-child(2) > div > div:first-child {
  max-width: 100% !important;
}
/* -------------------END------------------------ */


/* ------------------START----------------------- */
/*.sc-fqkvVR { */
.reorder-board > div:first-child > div:first-child > div > div:nth-child(2) {
  min-height: 205px !important;
}
/* -------------------END------------------------ */


/* ------------------START----------------------- */
/*.sc-fqkvVR > div {*/
.reorder-board > div:first-child > div:first-child > div > div:nth-child(2) > div {
  width: 100% !important;
}
/* -------------------END------------------------ */


/* ------------------START----------------------- */
/*.sc-dhKdcB span { */
.reorder-board > div:first-child > div:first-child > div > div:nth-child(2) > div > div:first-child > div:first-child span {
  display: none;
}
/* -------------------END------------------------ */



/* ------------------START----------------------- */
.krVTcw {
  padding:10px !important;
}
/* -------------------END------------------------ */



/*.questionsArea .sc-kAyceB span {*/
.questionsArea .reorder-board > div:first-child > div:first-child > div > div:nth-child(1) > div:first-child span {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
/*.questionsArea .sc-eqUAAy  {*/
.questionsArea .reorder-board > div:first-child > div:first-child > div {
  background: transparent;
  margin: 0 !important;
  padding-right: 85px;
  position: relative;
}
/*.questionsArea .sc-fqkvVR {*/
.questionsArea .reorder-board > div:first-child > div:first-child > div > div:nth-child(2) {
    border: 1px solid #1e9693;
    border-radius: 3px;
}
/*.questionsArea .sc-dhKdcB {*/
.questionsArea .reorder-board > div:first-child > div:first-child > div > div:nth-child(2) > div > div:first-child > div:first-child {
    border-radius: 0;
    padding: 8px;
    font-size: 14px;
    margin: 2px;
    border: 1px solid #1e9693;
    background: rgba(53, 220, 216,0.25);
    color: #1e9693;
}
/*.sc-fqkvVR > div:focus {*/
.questionsArea .reorder-board > div:first-child > div:first-child > div > div:nth-child(2) > div:focus {
  border: none;
  outline: 0;
}