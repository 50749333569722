userRecordLeftInr.sidebar:after,
.off-canvas-sidebar:after {
    background: #eaf2fc !important;
}
.sidebar {
    padding: 20px 10px 30px;
}
.sidebar,
.off-canvas-sidebar {
    width: 240px;
}
.main-panel {
    width: calc(100% - 240px);
}
.sidebar .sidebar-wrapper .user,
.sidebar .sidebar-wrapper li.active>a:not([data-toggle="collapse"]):after,
.sidebar .sidebar-wrapper li.active>[data-toggle="collapse"]+div .nav li:after,
.sidebar .sidebar-wrapper li.active>a:not([data-toggle="collapse"]):before {
    display: none;
}
.sidebar .nav li>a,
.off-canvas-sidebar .nav li>a,
.sidebar .nav i,
.off-canvas-sidebar .nav i {
    color: #6e6e6e !important;
    opacity: 1;
    text-transform: capitalize;
    font-weight: 500;
}
.sidebar li.active a,
.sidebar li a:hover {
    background: #1962d8;
    border-radius: 10px;
}
.sidebar a:hover i,
.sidebar a:hover p {
    color: #fff !important;
}
.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a {
    /*margin-left: 0;
    margin-right: 0;*/
    margin: 1px 1px 0;
}
.sidebar .nav p, .off-canvas-sidebar .nav p {
    line-height: 18px;
    font-size: 14px !important;
}
.sidebar .nav i, .off-canvas-sidebar .nav i {
    margin-top: -6px;
}
.sidebar .logo, .off-canvas-sidebar .logo {
    padding: 20px;
    background: #fff;
    border-radius: 0;
    margin-bottom: 70px;
    box-shadow: 0px 12px 20px 0px #d1dff0;
    transition: all 0.7s ease-in-out 0s;
}
.logo img {
    height: 60px;
    width: auto !important;
    transition: all 0.10s ease-in-out 0s;
}
.sidebar-mini .sidebar .logo,
.sidebar-mini .off-canvas-sidebar .logo {
    padding: 0px;
    background: transparent;
    box-shadow: none;
}
.sidebar-mini .logo img {
    width: 100% !important;
    height: auto;
}
.navbar {
    margin: 0px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 20px;
  }
  
  .user-info-container {
    display: flex;
    align-items: center; 
  }
.bell-icon-container {
    color: #397cd4;
    position: relative;
    margin-right: 60px;
}
.profile-picture {
    width: 100%;
    height: 100%;
}
  
  .cardBody {
    margin-top: 80px;
    
  }
.user-info-container {
    display: flex;
    align-items: center;
}

.bell-icon-container,
.profile-picture-container,
.username-container {
    margin-left: 20px;
}

.bell-icon-container {
    color: #397cd4;
}
.profile-picture {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: top center;
}
.cardBody {
    margin-top: 80px;

}

.bail_icon {
    color: #397cd4;
    height: 20px;
    width: 20px;
    margin-right: 10px;

}

.upperCard {
    margin-top: 80px;
    margin-bottom: 50px;
}

#la-free-mt-app-testimonial-section {
    margin: auto;
}
.main-panel .navbar.navbar-transparent {
    border-bottom: 1px solid #ddd;
}
.user-info-container .bi-bell-fill {
    font-size: 30px;
    color: #c0d6ee;
}
.bell-icon-container span.notiCount {
    position: absolute;
    top: 4px;
    right: -6px;
    background: #1775d4;
    border-radius: 20px;
    height: 22px;
    width: 22px;
    font-size: 10px;
    color: #fff;
    border: 2px solid #f3f8fb;
    text-align: center;
}
.userRecordLeft {
    margin-bottom: 20px;
}
.userRecordLeftInr {
    color: #d1dff0;
    height: 100%;
    border-radius: 20px;
    margin-bottom: 1px;
    background: #1962d8;
    padding: 22px;
}
.userRecordRightInr {
    background: #090249;
}
.sidebar-mini .sidebar .sidebar-wrapper {
  width: auto !important;
}
.userRecordLeftInr text {
    font-size: 16px;
    opacity: 1;
    color: #fff;
    font-weight: 500;
}
.userRecordLeftInr h5 {
    margin: 0;
    font-weight: 500;
    font-size: 42px;
    color: #fff !important;
}
.userRecordLeftInr a {
    font-size: 20px;
    font-weight: 500;
    display: block;
    vertical-align: top;
    color: #23c6fb;
}
.userRecordLeftInr a:hover {
    text-decoration: underline !important;
}
.userRecordLeftInr a.d-block {
    font-size: 20px;
    color: #fff;
}
.userInfoCard2 {
    display: flex;
    align-items: center;
    background-color: #090249;
    color: #eaf2fc;
    width: 100%;
    margin: 5px;
    border-radius: 20px;
}
.mainCard2 .row {
    width: 104%;
}
.mainCard2 {
    display: flex;
    color: #eaf2fc;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-height: 275px;
    background-color: #090249; 
    background-image: url('/images/shadow-bg-01.png'), url('/images/shadow-bg-02.png');
    background-position: -80px -70px, 80px 110px;
    background-repeat: no-repeat;
}
.card1 {
    background-color: rgb(5, 170, 74);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0 51px 30px;
    flex-direction: column;
    padding: 20px;
}
.profile-picture-container {
    height: 48px;
    width: 48px;
    background: #fff;
    border: 3px solid #013384;
    border-radius: 50px;
    overflow: hidden;
}
.username-container {
    margin-left: 10px;
}
.username-container span.username {
    font-weight: 500;
    text-transform: capitalize;
    font-size: 15px;
}
.username-container .userType {
    display: block;
    vertical-align: top;
    color: #1c3563;
    font-size: 10px;
    font-weight: 500;
    margin-top: -4px;
}
.card3 {
    margin: 10px;
    padding: 10px;
    font-size: small;
}
.card4 {
    background-color: #1962d8;
    border-top-left-radius: 25px;
    padding: 30px 20px;
    background-image: url(/images/calendar.png);
    background-repeat: no-repeat;
    background-position: 85% 85%;
    background-size: 67px;
    margin-left: auto;
    flex: none;
    max-width: 29%;
    border-bottom-left-radius: 25px;
}
.card4ExamDate {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 10px;
    margin-bottom: 18px;
    border-bottom: 3px solid #feff9d;
}
.card4ExamDate span,
.card4DaysLeft span {
    display: block;
    vertical-align: top;
    width: 100%;
    color: #feff9d;
    font-size: 23px;
    font-weight: bold;
}
.card4DaysLeft {
    font-size: 16px;
    font-weight: 500;
}
.mainCard2 .row .card1 text {
    font-size: 18px;
    display: block;
    vertical-align: top;
    width: 100%;
    text-align: center;
}
.mainCard2 .row .card1 h4 {
    width: 98px;
    height: 98px;
    border: 6px solid #fff;
    border-radius: 50%;
    color: #fff !important;
    font-weight: 600;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 32px;
    margin: 8px 0 0;
}
.mainCard2 .row .card1 text {
    font-size: 18px;
    display: block;
    vertical-align: top;
    width: 100%;
    text-align: center;
    color: #fff;
}
.setTarget.col {
    max-width: 17%;
}
.courseTarget .col {
    font-size: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
}
.courseTarget .col:first-child, .courseTarget .col:nth-child(2) {
    margin-bottom: 20px;
}
.courseTarget .col text {
    font-size: 32px;
    color: #6886f9;
    font-weight: 500;
}
.courseTarget.col {
    margin: 50px 0;
}
.setTarget.col {
    max-width: 17%;
    margin: 57px 0;
}
.setTarget a {
    color: #fff;
    background: #1962d8;
    display: inline-block;
    vertical-align: top;
    padding: 5px 15px;
    border-radius: 15px;
    font-weight: 500;
}
.setTarget a:hover {
    color: #1962d8;
    background: #FFF;
    transition: 0.3s ease all;
}
.instVidsOuter {
    margin-top: 40px;
}
.single-testimonial iframe {
    height: 362px;
}
.sidebar-mini .sidebar:hover .sidebar-wrapper > .nav li > a p,
.sidebar-mini .sidebar .sidebar-wrapper > .nav li > a p {
    transform: translate3d(0px, 0, 0) !important;
    opacity: 0 !important;
    height: 0;
    width: 0;
    text-indent: -200px;
}
.sidebar-mini .sidebar .nav i, .sidebar-mini .off-canvas-sidebar .nav i {
    float: none;
    margin: auto;
}
.sidebar-mini .sidebar .sidebar-wrapper > .nav li > a {
    text-align: center;
}
.sidebar .nav, .off-canvas-sidebar .nav {
  margin-bottom: 20px;
}
.sidebar .nav li:nth-last-child(2), .off-canvas-sidebar .nav li:nth-last-child(2) {
  margin-top: 100px;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #090249; 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #090249; 
}
.ps__thumb-y {
    background-color: #090249 !important;
}
.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
    width: 6px !important;
}
.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
    background-color: transparent;
}
.sidebar-mini .nav li > a > i {
    line-height: 18px !important;
    font-size: 18px !important;
}
.sidebar-mini .nav li > a {
    padding: 5px !important;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success:active:focus, .btn-success:active:hover, .btn-success.active:focus, .btn-success.active:hover,
.show > .btn-success.dropdown-toggle,
.show > .btn-success.dropdown-toggle:focus,
.show > .btn-success.dropdown-toggle:hover,
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-primary {
    background-color: #1962d8 !important;
}
.question-list-table.table .nav-link p,
.mock-open.table .nav-link p {
    margin: 0 0px 0 8px;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out 0s;
}
.question-list-table.table .nav-link i,
.mock-open.table .nav-link i {
    font-size: 17px;
    transition: all 0.2s ease-in-out 0s;
}
.question-list-table.table .nav-link,
.mock-open.table .nav-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.2s ease-in-out 0s;
}
.question-list-table.table .nav-link:hover i, .question-list-table.table .nav-link:hover p,
.mock-open.table .nav-link:hover i, .mock-open.table .nav-link:hover p {
    color: #1775d4;
}
.btn-success {
    background-color: #090249 !important;
}

.speakingBar .nextPrev a:hover {
    color: #1775d4;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
}
.speakingBar .numbersList span:hover {
    color: #1775d4;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
    background-color: #FFF;
}
.para-form {
    white-space: pre-line;
}
.questionsArea .instruction-label {
    padding-bottom: 3px;
    padding-left: 6px;
    -webkit-box-shadow: 0 3px 6px -4px black;
}
.practiceContent .actionButtons {
    margin-top: 15px;
}
.practiceContent .actionButtons button {
    margin-left: 20px;
}
.text-speak-icon {
    border: 2px solid #0844a3;
    padding: 1px 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
}
.text-speak-icon:hover {
    background: #0844a3;
    color: #EEE;
}

/*@media query start*/
@media screen and (max-width: 1700px) {
.testRecord {
    padding: 4px;
}
.mainCard1.col-md-5.col-sm-5 {
    max-width: 38%;
}
.studyTargetOuter .col-md-7.col-sm-7 {
    max-width: 62%;
    flex: none;
}
.setTarget a {
    padding: 6px 14px;
    font-size: 16px;
}
.courseTarget.col {
    max-width: 25%;
    flex: none;
}
.card1 {
    max-width: 21%;
}
.setTarget.col {
    max-width: 19%;
}
.testRecord h4 {
    margin: 0 0 4px;
}
.testRecord span {
    margin-top: 2px;
}
.userRecordLeftInr a {
    font-size: 19px;
}
.testRecord .icon-wrapper {
    height: 80px;
    width: 80px;
}
.testRecord .icon-wrapper i {
    font-size: 34px;
}
.testRecord .text-content {
    padding-left: 12px;
}

}
@media screen and (max-width: 1366px) {
.numbered-ul li {
  margin: 0 .6% 0 0;
  font-size: 12px;
  min-width: 21.4px;
}
.skillsBreakdown .infoBlock {
  max-width: 40%;
}
.numbersList {
  padding: 0 10px;
}
.numbersList span {
    width: 32px;
    height: 32px;
    line-height: 30px;
    font-size: 14px;
}
.speakingBar h4 {
    font-size: 20px;
    margin: 0 0 10px;
    padding: 15px 15px 0;
}
.speakingBar h4 i {
    font-size: 22px;
}
.nextPrev {
    padding: 15px 2px;
}
.nextPrev a {
    margin: 0 3px 7px;
    font-size: 13px;
}
.quesStatus span {
    font-size: 15px;
}
.quesStatus span:before {
    width: 38px;
    height: 20px;
    top: 4px;
}
.quesStatus > div {
    padding-left: 40px;
    margin-bottom: 3px;
}
.quesStatus span:before {
    width: 20px;
    height: 17px;
    top: 5px;
    left: -30px;
}
.topArrow {
  top: 13px;
}
.topArrow a, .topArrow a:hover, .topArrow a:focus {
    font-size: 30px;
}
.topArrow .rightArrow {
    margin-left: 7px;
}
.speakingStatus .doubleImges .col-sm-6 {
    max-width: 100%;
    flex: auto;
    margin: 0 0 10px;
}
.testRecord {
    padding: 0px;
}
.testRecord .icon-wrapper {
    height: 60px;
    width: 60px;
}
.testRecord .icon-wrapper i {
    font-size: 25px;
}
.testRecord h4 {
    font-size: 17px;
}
.testRecord span.doneQues, .testRecord span {
    font-size: 12px;
}
.testRecord .text-content {
    padding-left: 8px;
    padding-right: 8px;
}
h3.cardTitle {
    font-size: 16px;
}
.userRecordLeftInr {
    padding: 17px;
}
.userRecordLeftInr h5 {
    font-size: 34px;
}
.userRecordLeftInr text {
    font-size: 13px;
}
.userRecordLeftInr a {
    font-size: 15px;
}
.userRecordLeft {
    padding: 0 6px;
}
.mainCard2 {
    min-height: 225px;
}
.card1 {
  margin: 40px 0 40px 18px;
  border-radius: 17px;
}
.courseTarget.col {
    margin: 40px 0;
}
.setTarget.col {
    margin: 47px 0;
}
.card4 {
    padding: 40px 20px;
}
.mainCard2 .row .card1 text {
    font-size: 15px;
}
.mainCard2 .row .card1 h4 {
    width: 78px;
    height: 78px;
    font-size: 22px;
}
.courseTarget .col {
    font-size: 14px;
}
.courseTarget .col text {
    font-size: 24px;
}
.setTarget a {
    font-size: 12px;
}
.wrapper .sidebar .nav p, .wrapper .off-canvas-sidebar .nav p {
    font-size: 14px;
}
.sidebar .nav i, .off-canvas-sidebar .nav i {
    margin-right: 7px;
}
.single-testimonial iframe {
    height: 280px;
}
.bell-icon-container {
    margin-right: 40px;
}

}
@media screen and (max-width: 1200px) {
.sidebar, .off-canvas-sidebar {
    width: 240px;
}
.sidebar .sidebar-wrapper, .off-canvas-sidebar .sidebar-wrapper {
    width: 100%;
}
.main-panel {
    width: calc(100% - 240px);
}
.sidebar .logo, .off-canvas-sidebar .logo {
    margin-bottom: 50px;
}
.wrapper .sidebar .nav p, .wrapper .off-canvas-sidebar .nav p {
    font-size: 12px;
}
.sidebar .nav i, .off-canvas-sidebar .nav i {
    margin-right: 5px;
    line-height: 27px;
}
.sidebar .nav i, .off-canvas-sidebar .nav i {
    font-size: 17px !important;
}
.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a {
    margin: 0px;
}
.sidebar {
    padding: 20px 10px 20px;
}
.testRecord {
    padding: 0px 0 20px;
}
.sidebar .nav li > a, .off-canvas-sidebar .nav li > a {
    font-size: 12px;
    padding: 6px 8px;
}
.mainCard2 {
    min-height: 1px;
    background-position: -80px -70px, -100px 120px;
}
h3.cardTitle {
    font-size: 14px;
}
h3.cardTitle:before {
    top: 7px;
    height: 2px;
}
.card1 {
  display: none;
}
.mainCard2 .row {
    width: 108%;
}
.courseTarget.col {
    max-width: 36%;
    flex: none;
}
.setTarget.col {
    max-width: 26%;
    flex: none;
    padding: 0 8px;
}
.card4 {
    max-width: 38%;
}
.courseTarget .col {
    font-size: 13px;
}
.userRecordLeftInr {
    padding: 10px;
}
.userRecordLeftInr h5 {
    font-size: 25px;
}
.userRecordLeftInr a {
    font-size: 13px;
}
.single-testimonial iframe {
    height: 180px;
}

}
@media screen and (max-width: 991px) {
.main-panel {
    width: 100%;
}
.testAttempts.mt-5 {
    margin-top: 0 !important;
}
.upperCard {
    margin-top: 60px;
    margin-bottom: 50px;
}
.testimonial-carousal .col-lg-6 {
    max-width: 50%;
}

}
@media screen and (max-width: 767px) {
.testAttempts .col {
    padding: 0 10px;
}
.testRecord .icon-wrapper {
    height: 40px;
    width: 40px;
}
.testRecord .icon-wrapper i {
    font-size: 20px;
}
.mainCard2 .row {
    width: 100%;
    flex-direction: column;
}
.courseTarget.col {
    max-width: 100%;
    margin: 20px 0 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    display: flex;
}
.courseTarget.col .userRecord > .col {
    max-width: 25%;
    text-align: center;
}
.courseTarget.col .userRecord {
    flex-direction: row;
}
.courseTarget .col text {
    font-size: 24px;
    margin: 0 auto;
}
.setTarget.col {
    max-width: 100%;
    margin: 0;
    text-align: center;
}
.card4 {
    max-width: 100%;
    margin: 20px 0;
    border-radius: 25px;
}

}
@media screen and (max-width: 640px) {
.testAttempts.mt-5 {
    padding-bottom: 0px;
}
.testAttempts .col {
    margin-bottom: 20px;
}
.testAttempts .col:nth-child(2) .testRecord {
    border: none;
}
.card4 {
    padding: 10px 20px;
}
.userRecordLeft {
    margin-bottom: 10px;
}
.testimonial-carousal .col-lg-6 {
    max-width: 500px;
    margin: auto;
}
.single-testimonial iframe {
    height: 248px;
}
.sidebar .logo, .off-canvas-sidebar .logo {
    padding: 10px;
}

}
@media screen and (max-width: 480px) {
.testRecord {
    padding: 0;
    border: none;
}
.mainCard1.col-md-5.col-sm-5 {
    max-width: 100%;
}
.mainCard1 > .row {
    width: 260px;
    margin: auto auto 40px;
}
.userRecordLeftInr {
    padding: 20px 10px;
}
.studyTargetOuter .col-md-7.col-sm-7 {
    max-width: 100%;
}
.courseTarget.col .userRecord > .col {
    max-width: 50%;
    align-items: center;
}
.setTarget.col {
    margin: 30px 0 20px;
}
.testimonial-carousal .col-lg-6 {
    max-width: 300px;
}
.single-testimonial iframe {
    height: 148px;
}
.navbar {
    justify-content: center;
    width: 100%;
    padding: 10px 0px;
}
.bell-icon-container {
    margin-right: 20px;
}
.profile-picture-container {
    height: 38px;
    width: 38px;
}

}