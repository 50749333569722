.border-label {
    position: absolute;
    right: 0px;
    font-size: 16px;
    background-color: #ebb8b8;
    color: #fff;
    padding: 1px 5px;
}
.three-border {
    border-top: 2px solid #ebb8b8;
    border-left: 2px solid #ebb8b8;
    border-right: 2px solid #ebb8b8;
}
.border-three {
    border-bottom: 2px solid #ebb8b8;
    border-left: 2px solid #ebb8b8;
    border-right: 2px solid #ebb8b8;
}
.scoring-mode button {
	border: none;
    text-transform: capitalize;
    font-weight: 400;
    padding: 10px 15px;
    background-color: transparent !important;
    color: #333;
    border-radius: 0 !important;
    font-size: 18px;
    font-weight: bold;
}
.scoring-mode .btn:focus,
.scoring-mode .btn:active,
.scoring-mode .btn:active:focus {
    background-color: none !important;
}
.scoring-mode .btn:hover,
.scoring-mode .btn.btnActive {
    border-bottom: 2px solid #333 !important;
    border-bottom-color: #0945a3 !important;
    color: #0945a3 !important;
}